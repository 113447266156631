.footer {
    margin: 60px auto 0 auto;
    max-width: 1440px;
}

.footerDiv{
    padding: 1rem 14rem;
}

.footerHeader {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo {
    align-items: flex-start;
}

.socialIcons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    cursor: pointer;
  }
  
  .socialIcons a {
    padding: 9px;
    margin: 0 10px;
    transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease; 
  }
  
  .socialIcons a img {
    width: 40px; 
    height: 40px;
    transition: transform 0.3s ease; 
  }
  
  /* Hover effect for icons */
  .socialIcons a:hover img {
    transform: scale(1.1); 
    opacity: 0.8; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }  

.hr {
    border: 0.5px solid #FB8B19;
    border-radius: 5px;
    margin-top: 20px
}


.m40 {
    margin: 40px 0;
}


.footerSubHead {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 25px;
    font-style: normal;
}

.footerOptions {
    color: #606060;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.footerOptions a {
    list-style-type: none;
    display: block;
    margin-top: 15px;
    text-decoration: none;
    color: #606060;
}

.footerOptions div {
    display: block;
    margin-top: 15px;
    font-style: normal;
    color: #606060;
}

.footerBase {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #606060;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.pointer{
    cursor: pointer
}

@media only screen and (min-width: 769px) and (max-width: 1110px) {
    .footer {
        padding: 20px 5rem;
    }

    .footerDiv{
        padding: 0;
    }
}

@media (max-width: 768px) {

    .footer {
        margin: 0;
        padding: 20px 5vw;
    }

    .footerDiv{
        padding: 20px 0;
    }

    .footerHeader {
        display: block;
    }

    .footerSubHead {
        margin-top: 10px;
    }

    .footerOptions {
        margin-top: -15px
    }

    .socialIcons {
        margin: 10px 0 0 -10px;
        justify-content: flex-start;
    }

    .socialIcons a {
        padding: 0;
        margin: 0 10px;
    }

    .footerBase {
        display: block;
    }

    .m40 {
        margin: 20px 0;
    }
}