a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.navbarContainer {
    background: #ffffff;
    height: 90px;
    top: 0;
    right: 0;
    left: 0;
    filter: webkit-box-shadow(0 4px 4px rgba(128, 128, 128, 0.5));
    // -moz-box-shadow: 0 4px 4px rgba(128, 128, 128, 0.5);
    box-shadow: 0 4px 4px rgba(128, 128, 128, 0.1);
    // // filter: drop-shadow(0px 4px 4px rgba(128, 128, 128, 0.5));
    position: fixed;
    cursor: pointer;
    z-index: 10;
}

.navbar {
    display: flex;
    padding: 20px 14rem;
    align-items: center;
    margin: 0 auto;
    max-width: 1440px;
}

.navbarLogo {
    justify-content: flex-start;
    width: 20%;
}


.menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
}

.arrow {
    margin-top: 4px
}

.menuItem {
    text-decoration: none;
    font-style: normal;
    font-family: Lexend !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-right: 20px;
    color: #606060;
    white-space: nowrap;
    display: inline;
}

.menuItem:hover {
    padding: 0;
    color: #FB8B19;
}

.activeMenu {
    text-decoration: none;
    font-style: normal;
    font-family: Lexend !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-right: 20px;
    color: #FB8B19;
}

.activeColorText {
    color: #FB8B19;
}

.ml {
    margin-left: 0.5rem;
    color: #272727
}

.navbar a:hover,
.dropdown:hover .dropbtn {
    background-color: #FB8B19;
}

.dropdownContent {
    display: none;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdownContent a {
    float: none;
    color: black;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdownContent a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.downloadButton {
    background-color: #FB8B19;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF
}

.fhcButton {
    border-radius: 10px;
    background: var(--green-1, #2C8900);
    color: #ffffff;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    margin-left: 20px
}

.navbarContainerMobile {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background: #ffffff;
    height: 60px;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(128, 128, 128, 0.1));
}

.navBarMobile {
    display: flex;
    align-items: center;
    padding: 10px 5vw;
}

.logoMobile {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.mobileMenu {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #606060;
    cursor: pointer;
}

.mobileMenuDiv {
    background: #ffffff;
    border: drop-shadow(0px 4px 4px rgba(128, 128, 128, 0.1));
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 60px);
    font-size: 16px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.mobileMenuList {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.mobileMenuItem {
    font-size: 20px;
    color: #606060;
    cursor: pointer;
    text-align: center
}

.mobileMenuItem:hover {
    padding: 0;
    color: #FB8B19;
}

.hr {
    border: 0.5px solid #808080;
    border-radius: 5px;
    width: 100%;
}

.mobileMenuItemName {
    margin-bottom: 15px
}


.divflex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}


.buttonText {
    margin-left: 8px;
    text-align: left;
    // margin: auto;
}

.buttonText p {
    margin-top: -10px;
}

@media (min-width: 1700px) {
    .navbarLogo {
        margin-left: 14rem
    }

    .menu {
        margin-right: 14rem
    }
}

@media only screen and (min-width: 1401px) and (max-width: 1490px) {
    .navbarLogo {
        margin-left: 3rem
    }

    .menu {
        margin-right: 3rem
    }
}

@media only screen and (min-width: 1500px) and (max-width: 1590px) {
    .navbarLogo {
        margin-left: 5rem
    }

    .menu {
        margin-right: 5rem
    }
}


@media only screen and (min-width: 1600px) and (max-width: 1699px) {
    .navbarLogo {
        margin-left: 9rem
    }

    .menu {
        margin-right: 9rem
    }
}

@media only screen and (min-width: 769px) and (max-width: 1100px) {
    .navbar {
        padding: 20px 5rem;
    }
}


@media (max-width: 768px) {

    .downloadButton {
        padding: 15px;
        display: grid;
    }

    .downloadButton span {
        font-size: 20px;
    }

    .fhcButton {
        padding: 15px;
        display: grid;
        margin-right: 0
    }

    .fhcButton span {
        font-size: 20px;
    }
}