.accordion {
  width: 100%;
}

.accordionItem {
  position: relative;
}

.accordionTitle {
  display: flex;
  align-items: center;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  margin: 2rem auto 1rem autoZ;
}

.accordionTitleName {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 32px;
  line-height: 28px;
  color: #272727;
}

.accordionHyreTitleName {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  color: var(--grey-1, #272727);
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.icons {
  width: 5%;
  justify-content: flex-start;
}

.accordionTitle:hover {
  background-color: #fff;
}

.accordionContent {
  margin: 0;
}

hr:last-of-type {
  display: none;
}

.faqHeader {
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  color: #343770;
}

.faqAnswer {
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  margin: 0.5rem 0;
  color: #343770;
  text-align: justify;
}

.mlTitle {
  margin-left: 5%;
}

.accordionDivider {
  border: 0.5px solid #D9D9D9;
  border-radius: 5px;
  margin: 25px 0
}

.accordionDividerHyre {
  border: 0.5px solid #D9D9D9;
  border-radius: 5px;
  margin: 20px 0
}

.mp {
  margin-top: -30px;
  margin-bottom: 30px
}

.mpHyre {
  margin-top: 20px;
  margin-bottom: 30px
}

@media screen and (max-width: 768px) {
  .accordion {
    width: 100%;
    margin: 0 auto;
  }

  .accordionTitleName ,
  .accordionHyreTitleName {
    font-size: 2rem;
  }
}


@media screen and (max-width: 400px) {
  .mlTitle {
    margin-left: 0.2rem;
  }
}