body {
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

:root {
  font-size: 10px;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 7rem;
  line-height: 7.38rem;
  color: #272727;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 5rem;
  line-height: 6.2rem;
  color: #272727;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #272727;
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #272727;
}


h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #272727;
}

h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #272727;
}


p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #606060;
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 6rem;
    color: #272727;
    line-height: 7rem;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 4rem;
    line-height: 4.8rem;
    color: #272727;

  }

  p {
    font-style: normal;
    font-weight: 400;
    line-height: 2.8rem;
    color: #606060;
  }
}

@media (max-width: 768px) {
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 3.5rem;
    padding: 0;
    line-height: 5rem;
    color: #272727;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 4.2rem;
    color: #272727;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: #606060;
    // width: 90vw;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 3.3rem;
  }

}