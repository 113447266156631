.container {
  margin-top: 90px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.containerBody {
  max-width: 1440px;
  margin: 0 auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.inactiveLink {
  pointer-events: none;
  cursor: default;
}

.paddingH {
  padding: 30px 0;
}

.paddingHB {
  padding: 0 0 30px 0;
}

.parentDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  padding: 0 14rem;
  white-space: nowrap;
}

.headerBackground {
  background: linear-gradient(
    to right,
    RGBA(255, 230, 230, 0.8) 20%,
    #e4ffd7 100%
  );
  backdrop-filter: blur(10px);
  min-height: 570px;
  max-height: auto;
}

.parentDiv1 {
  margin: 0;
  display: flex;
  flex-direction: row;
  padding: 20px 14rem 70px 14rem;
}

.parentDiv2,
.parentDivHome,
.parentDivHome2,
.parentDiv12,
.parentDiv13 {
  display: flex;
  flex-direction: row;
  padding: 50px 14rem 70px 14rem;
}

.parentDivDownload {
  display: flex;
  flex-direction: row;
  padding: 40px 14rem;
}

.parentDivSave {
  display: flex;
  flex-direction: row;
  padding: 50px 14rem 20px 14rem;
}

.parentDivSave2 {
  padding: 30px 14rem;
  display: flex;
  align-items: center;
  /* Vertically aligns children (left and right divs) */
  justify-content: center;
  /* Adds space between the left and right divs */
  height: 500px;
  /* Full-height container for centering */
  position: relative;
  /* Ensures the image can be positioned relative to the container */
}

.parentDiv3 {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  max-height: auto;
  background: #fff;
  border-radius: 5px;
  padding: 0 0 30px 0;
}

.parentDiv4 {
  display: flex;
  flex-direction: row;
  padding: 20px 14rem;
}

.parentDiv5 {
  min-height: 150px;
  max-height: auto;
  background: #fb8b19;
}

.parentDiv6 {
  display: flex;
  flex-direction: column;
  padding: 20px 14rem;
  align-self: center;
}

.parentDiv7 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  min-height: 300px;
  max-height: auto;
  background: #fff5eb;
  border-radius: 5px;
  padding: 0 2rem;
}

.parentDiv8 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 714px;
  max-height: auto;
  background: #ecffe3;
  border-radius: 5px;
}

.parentDiv9 {
  padding: 30px 14rem;
  min-height: 494px;
  max-height: auto;
  background: linear-gradient(98.12deg, #e4ffd7 30.86%, #ffe1c3 77.13%);
}

.parentDiv10 {
  min-height: 260px;
  background: #fb8b19;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parentDiv11 {
  display: flex;
  padding: 40px 14rem;
  min-height: 440px;
  max-height: auto;
  background: #fff9f2;
}

.brownBackground {
  background: #fff9f2;
}

.parentDiv12 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  padding: 0 14rem;
}

.parentDiv14 {
  padding: 30px 14rem;
  min-height: 494px;
  max-height: auto;
  background: #fff;
}

.greyBackground {
  min-height: 494px;
  max-height: auto;
  background: var(--grey-9, #f8f7f7);
}

.greyBackgroundReferral {
  padding: 40px 0;
  background: var(--grey-9, #f8f7f7);
}

.parentDiv15 {
  padding: 30px 14rem;
}

.parentDiv16 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 14rem 80px 14rem;
}

.parentDivCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 14rem;
}

.parentDivInvest {
  display: flex;
  align-items: center;
  padding: 10px 14rem 60px 14rem;
}

.parentDivInvest2 {
  display: flex;
  align-items: center;
  padding: 40px 14rem;
}

.parentDivHyre {
  padding: 30px 14rem 0 14rem;
  min-height: 494px;
  max-height: auto;
  background: var(--grey-9, #f8f7f7);
}

.paddingFaq {
  padding: 100px 0 0 0;

  .leftDiv {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    align-self: flex-start;
  }
}

.img2 {
  width: 80%;
}

.leftDiv {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.leftDiv1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

.leftDivInvest {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.rightDivInvest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.rightDiv {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.rightDiv1 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.leftDiv2 {
  width: 60%;
  // margin: 0 20px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.leftDiv3 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.leftDiv4 {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightDiv2 {
  width: 40%;
  display: flex;
  margin-left: 3rem;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.rightDiv3 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: 40px;
}

.rightDiv4 {
  width: 40%;
  display: flex;
  margin-left: -200px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.leftDiv5 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.leftDiv8 {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.rightDiv5 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-right: 1.5rem;
}

.rightDiv6 {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  margin-left: 5rem;
  align-self: center;
}

.rightDiv7 {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rightDiv8 {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.leftDivSave {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centers content vertically */
  text-align: left;
  /* Optional: Aligns text to the left */
}

.rightDivSave {
  width: 40%;
  display: flex;
}

.rightDivSave img {
  position: absolute;
  bottom: 0;
  /* Aligns image to the bottom of the parent container */
  // left: 50%; /* Ensures horizontal centering */
  // transform: translateX(-50%); /* Corrects horizontal alignment */
  max-width: 100%;
  /* Makes the image responsive */
  height: 500px;
  /* Maintains aspect ratio */
}

.colouredDiv1 {
  width: 450px;
  height: 620px;
  background: #fff9f2;
  border-radius: 5px;
}

.colouredDiv2 {
  width: 450px;
  height: 620px;
  background: #f2ffec;
  border-radius: 5px;
}

.colouredDivHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: #272727;
}

.imageDiv {
  display: block;
  resize: none;
  overflow: hidden;
  border: none;
}

.imageDiv img {
  width: 100%;
  height: 100%;
}

.imageDiv2 img {
  width: 80%;
  height: 80%;
  display: block;

  resize: none;
  overflow: hidden;
  border: none;
}

.imageDiv3 img {
  width: 90%;
  height: 90%;
  display: block;

  resize: none;
  overflow: hidden;
  border: none;
}

.imageDiv4 img {
  width: 100%;
  height: 120%;
  display: block;

  resize: none;
  overflow: hidden;
  border: none;
}

.imageDiv5 img {
  width: 120%;
  height: 120%;
  display: block;

  resize: none;
  overflow: hidden;
  border: none;
}

.imageDiv6 img {
  width: 318px;
  height: 318px;
  display: block;

  resize: none;
  overflow: hidden;
  border: none;
}

.img {
  width: 70%;
}

.divDimension {
  padding: 20px 14rem;
}

.flex {
  display: flex;
}

.flex2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.flex3 {
  display: flex;
}

.flex4 {
  display: flex;
}

.divflex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.divflexColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 2rem;
}

.textJustify {
  text-align: justify;
}

.center {
  text-align: center;
}

.parentPadding {
  padding: 10px 14rem;
  display: flex;
}

.parentPadding1 {
  padding: 10px 14rem 60px 14rem;
}

.parentPadding2 {
  padding: 5px 6rem 60px 6rem;
  display: flex;
}

.parentPadding3 {
  width: 50%;
  margin: 0 auto 20px auto;
}

.p2 {
  padding: 2rem;
}

.p2rem {
  padding: 2rem;
}

.pt2 {
  padding-top: 2rem;
  padding-left: 1.5rem;
}

.p3 {
  padding: 0 0 0 5rem;
}

.p5 {
  padding: 0 5rem;
}

.p15 {
  padding: 0 12rem;
  white-space: normal;
}

.p20 {
  padding: 0 8rem;
  white-space: normal;
}

.p25 {
  padding: 0 25rem;
  white-space: normal;
}

.m1 {
  margin: 1rem;
}

.mtbSave {
  margin-bottom: -10px;
}

.mb2rem {
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
}

.mtb2rem {
  margin: 2rem 0;
}

.mtbhyre {
  margin: 2rem 0 0 0;
}

.mtb1lr0 {
  margin: 1rem 0;
}

.mtb1lr4 {
  margin: 1rem 0 4rem 0;
}

.mtb2lr0 {
  margin: 2rem 0 5rem 0;
}

.mtb0lr1 {
  margin: 0 1rem;
}

.mtb0l3 {
  margin: 0 0 0 3rem;
}

.mtb0r3 {
  margin: 0 1rem 0 1rem;
}

.m2 {
  margin: 4rem;
}

.pInvest {
  padding: 4rem 0 0 0;
}

.m2b {
  margin: 4rem 4rem 2rem 4rem;
}

.m40 {
  margin: 40px 0;
}

.m50 {
  margin: 50px 0;
}

.m50b {
  margin: 50px 0 0 0;
}

.m60 {
  margin: 60px 0;
}

.m70 {
  margin: 70px 0 0 0;
}

.m80 {
  margin: 80px 0 0 0;
}

.mtb80 {
  margin: -40px 0 80px 0;
}

.mtb100 {
  margin: 100px 0;
}

.m90 {
  margin: 90px 0 0 0;
}

.mdownload {
  margin: 0;
}

.mPrivacy {
  margin: 90px 0 0 0;
}

.mt1 {
  margin-top: -18px;
}

.mt2 {
  margin-top: -8px;
}

.mtIndex {
  margin-top: -2px;
}

.mt3 {
  margin-top: -40px;
}

.mthome {
  margin-top: -90px 0 0 0;
}

.mt4 {
  margin-top: -30px;
}

.mt5 {
  margin: 4rem 0 0 5rem;
}

.mt6 {
  margin-top: 10px;
}

.mt7 {
  margin-top: -60px;
}

.mt1rem {
  margin-top: 1rem;
}

.mt16 {
  margin-top: 2rem;
}

.mt3rem {
  margin-top: 3rem;
}

.mt100 {
  margin: 100px auto 50px auto;
}

.mhalf {
  margin: 0.5rem 0;
}

.ml1 {
  margin-left: 3rem;
}

.ml2 {
  margin-left: 10px;
}

.mtLines {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.justify {
  text-align: justify;
}

.downloadButton {
  width: 110px;
  height: 40px;
  background: #fb8b19;
  border-radius: 5px;
  color: #ffffff;
  padding: 6px 25px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.downloadButton:hover {
  transform: translateY(-4px);
  /* Slight lift on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  /* Slightly stronger shadow */
}

.downloadButton:active {
  transform: translateY(0);
  /* Return to the original position on click */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.downloadButton2 {
  width: 110px;
  height: 40px;
  background: #ffffff;
  border-radius: 5px;
  color: #fb8b19;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-right: 40px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.downloadButton2:hover {
  transform: translateY(-4px);
  /* Slight lift on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  /* Slightly stronger shadow */
}

.downloadButton2:active {
  transform: translateY(0);
  /* Return to the original position on click */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.downloadButton3 {
  width: 250px;
  background: #ffffff;
  border-radius: 5px;
  color: #fb8b19;
  padding: 17px 35px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;
  font-size: 40px;
}

.downloadButton span {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
}

.downloadButton2 span {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
}

.downloadButton3 span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.investButton {
  background: #fb8b19;
  border-radius: 5px;
  width: 200px;
  color: #fff;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  margin-top: 2.7rem;
}

.buttonText {
  margin-left: 8px;
  text-align: left;
  // margin: auto;
}

.buttonText p {
  margin-top: -10px;
}

.white {
  color: #ffffff;

  span {
    br {
      display: none;
    }
  }
}

.greenLink {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s ease;
}

.greenLink:hover {
  color: #007bff;
  /* Change to your desired hover color */
}

.green {
  color: #2c8900 !important;
  text-align: left;
  float: left;
  cursor: pointer;
  transition: color 0.2s ease;
}

.linkArrow {
  margin-top: -1px;
  margin-left: 5px;
  text-align: right;
  font-weight: 600;
  float: left;
  transition: transform 0.2s ease;
}

/* Move the arrow slightly on hover */
.greenLink:hover .linkArrow {
  transform: translateX(4px);
}

.iHeaderWidth,
.iHeaderSave {
  width: 463px;
}

.iHeaderWidth2 {
  width: 510px;
}

.iHeaderDownload {
  width: 600px;
}

.headerWidth {
  width: 600px;
}

.iParagraphWidth {
  width: 332px;
}

.securityWidth {
  width: 807px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.bullet {
  padding-left: 44px;
  background-image: url("https://res.cloudinary.com/dztw4cjew/image/upload/v1676390271/Assets/bullet_.webp");
  background-repeat: no-repeat;
  background-position: 0 0.4em;
}

.commentDiv {
  width: 500px;
  height: 550px;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 3rem;
}

.indCommentDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 26px;
  gap: 26px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transform: rotate(-1.58deg);
}

.indCommentDiv2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
  padding: 0 26px;
  gap: 26px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transform: rotate(1.58deg);
}

.comment {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #272727;
}

.commentText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #606060;
}

.date {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #606060;
  margin-bottom: 12px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0 -1rem 0;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: -2rem 0 1rem 0;
}

.started {
  width: 320px;
}

.paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #606060;
}

.headerStay {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #272727;
}

.boxButton {
  background: #ffffff;
  margin: 0 40px;
  display: flex;
  height: 40px;
  box-sizing: border-box;
}

::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #c9c9c9;
}

.boxButton input {
  height: 100%;
  width: 100%;
  border: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px 0px 0px 5px;
  margin: 0;
  padding: 5px 15px;
  background: #ffffff;
  border-radius: 5px;
}

.boxButton button {
  height: 50px;
  padding: 5px 15px;
  width: 133px;
  border: none;
  color: #ffffff;
  background: #fb8b19;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0px 5px 5px 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

//invest page
.imageDivHeight {
  height: 110px;
}

.mtinvest {
  margin: 40px 0;
}

.textWidth {
  width: 465px;

  .mt1 {
    margin-top: -24px;
  }
}

.textWidth2 {
  width: 540px;

  .mt1 {
    margin-top: -24px;
  }
}

.investBox {
  flex-direction: column;
  align-items: center;
  padding: 22px;
  // width: 270px;
  height: 320px;
  background: #ffffff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #606060;
}

.feesBox {
  display: flex;
  width: auto;
  height: 480px;
  padding: 49px 19px;
  flex-direction: column;
  align-items: flex-start;
  gap: 87px;
  border-radius: 10px;
  margin: 40px 0;

  span {
    color: var(--grey-2, #606060);
    font-family: Lexend;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  .header {
    font-family: Lexend;
    font-size: 36px;
  }

  .fee {
    padding: 10px 20px;
    gap: 10px;
    border-radius: 50px;
    color: var(--grey-1, #272727);
    font-family: Lexend;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  .annualFee {
    color: var(--grey-1, #272727);
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: -40px;
  }

  .mt {
    margin: 15px 0;
  }

  img {
    margin-bottom: 30px;
  }
}

.feeSheet {
  color: var(--orange-1, #fb8b19);
  text-align: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-bottom: 30px;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.feeSheet:hover {
  color: var(--orange-1, #de7301);
}

.hyreBox {
  display: flex;
  width: auto;
  height: 313px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    color: var(--grey-1, #272727);
    text-align: center;
    font-family: Lexend;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .main {
    color: var(--grey-2, #606060);
    text-align: center;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 10px;
  }

  img {
    margin-bottom: 20px;
  }
}

.stepsHeaderFull {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 62px;
  color: #272727;
  margin-bottom: 2rem;
}

.stepsHeader {
  width: 70%;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 62px;
  color: #272727;
  margin-bottom: 2rem;
}

.greenBackground {
  background: #f3ffee;
  padding: 30px 0;
  margin-bottom: 30px;
}

.scrollContainer {
  position: fixed;
  z-index: 10;
  width: 100%;
  // max-width: 1440px;
  color: #272727;
  font-size: x-large;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 63px;
  max-height: auto;
  background: linear-gradient(
    to left,
    RGBA(255, 230, 230, 0.8) 20%,
    #e4ffd7 100%
  );
  backdrop-filter: blur(10px);
}

.scrollText {
  text-align: right;
  white-space: nowrap;
  /* animation properties */
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
  }

  to {
    -moz-transform: translateX(100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
  }

  to {
    -webkit-transform: translateX(100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

//save page
.mtsave {
  margin-top: -40px;
}

.downloadMaxWidth {
  max-width: 1440px;
  margin: 0 auto;
}

.savingGridWidth {
  width: 230px;
  text-align: center;
}

.savepwidth {
  width: 476px;
}

.saveBox1 {
  // width: 270px;
  height: 254px;
  background: #ffffff;
  border: 1px dashed #2c8900;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.saveBox2 {
  // width: 270px;
  height: 254px;
  background: #ffffff;
  border: 1px dashed #fb8b19;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.saveBox3 {
  // width: 270px;
  height: 254px;
  background: #ffffff;
  border: 1px dashed #bf0202;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flexContainer {
  display: flex;
  justify-content: center;
  gap: 2%;
}

.textPadding {
  color: #ffffff;
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;
  /* Ensures padding is included in width calculations */
  overflow-wrap: break-word;
  /* Allows text to wrap within the container */
}

.textPadding2 {
  color: #272727;
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;
  /* Ensures padding is included in width calculations */
  overflow-wrap: break-word;
  /* Allows text to wrap within the container */
}

.textPadding h3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 62.5px;
}

.textPadding2 h3 {
  color: #272727;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 62.5px;
}

.textPadding p {
  color: #ffffff;
  font-size: 18px;
  margin: 0 40px;
  line-height: 28px;
}

.textPadding2 p {
  color: #272727;
  font-size: 16px;
  margin: 0 40px;
  line-height: 28px;
}

.childDiv {
  background-color: #f0f0f0;
  width: 49%;
  height: 474px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 15px;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  padding: 15px;
  overflow: hidden;
  position: relative;
  /* Ensures precise placement of the image */
}

.childDivOne {
  background-color: #fb8b19;
}

.childDivTwo {
  background-color: #2c8900;
}

.childDivThree {
  width: 49%;
  height: 440px;
  padding: 15px;
  background-color: white;
  color: #272727;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;

  div {
    text-align: left;
  }

  h3 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 62.5px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }
}

.childDivFour {
  background-color: #fff3e7;
}

.childDivFive {
  background-color: #e4ffd7;
}

.childDivSix {
  background-color: #ffe6e6;
}

.childDiv img {
  display: block;
  position: absolute;
  bottom: 0;
  max-height: 300px;
  /* Adjust to ensure a large, visible image */
  width: auto;
  margin: 0;
  padding: 0;
}

.stepsChildDiv {
  margin-top: 30px;
  background-color: #f0f0f0;
  width: 32.7%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;
  align-items: left;
  border-radius: 8px;
  text-align: left;

  h4 {
    margin-bottom: 10px;
  }
}

.stepsChildDiv img {
  width: 50px;
  height: 50px;
  // margin-bottom: 10px;
}

//pay-bills
.payBillsBox {
  width: 287px;
  height: 276px;
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payBillsMargin {
  margin: 5rem 9rem 3rem 9rem;
}

//privacy-policy
.headerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  width: fit-content;
  height: 80px;
  background: #fb8b19;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
}

.subHeaderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 30px;
  height: 60px;
  background: #fff;
  border: 1px solid #fb8b19;
  border-radius: 5px;
  color: #fb8b19;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.privacyText {
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  color: #272727;
  margin-top: 1rem;
}

.disc {
  list-style-type: disc;
}

//About Us Page Styling
.backgroundImage {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("https://res.cloudinary.com/dztw4cjew/image/upload/v1676390030/Assets/about-us/image1_.webp");
  position: relative;
  height: 538px;
  border-radius: 5px;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backgroundImage h1 {
  color: transparent;
  -webkit-text-fill-color: #ffffff;
}

.imageText {
  color: #ffffff;
}

.aboutDivWidth1 {
  width: 60%;
  display: flex;
  justify-content: flex-start;
}

.aboutDivWidth2 {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.aboutDiv {
  width: auto;
  height: auto;
  padding: 2rem 6rem 2rem 6rem;
  background: #ffffff;
  border: 0.5px solid #fda144;
  box-shadow: 5px 20px 30px rgba(251, 139, 25, 0.1);
  border-radius: 10px;
}

//calculator
.calculatorWidth {
  width: 580px;
}

.calculatorWidth2 {
  width: 410px;
}

.calculatorDiv1 {
  width: 464px;
  height: auto;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
  margin-right: 3rem;
}

.calculatorDiv2 {
  width: 464px;
  height: auto;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
}

.calculatorFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.savingsHeaderMobile {
  display: none;
}

.educationHeaderMobile {
  display: none;
}

.retirementHeaderMobile {
  display: none;
}

.imageDivInvest img {
  width: 400px;
  height: auto;
}

//not found
.notFoundButton {
  width: 120px;
  height: auto;
  background: #fb8b19;
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 20px;
}

.notFoundText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272727;
  text-align: center;
}

.singleLine {
  white-space: nowrap;
  cursor: pointer;
}

@media only screen and (min-width: 769px) and (max-width: 1110px) {
  .parentDiv {
    padding: 10px 5rem;
  }

  .parentDiv1 {
    padding: 10px 5rem;
    min-height: 650px;
  }

  .parentDiv2,
  .parentDivHome,
  .parentDivHome2,
  .parentDiv12,
  .parentDiv13,
  .parentDivSave,
  .parentDivSave2 {
    padding: 10px 5rem 40px 5rem;
    min-height: 400px;
  }

  .parentDivDownload {
    padding: 10px 5rem 40px 5rem;
  }

  .parentDivCenter {
    padding: 60px 5rem;
    min-height: 400px;
  }

  .parentDivInvest {
    padding: 10px 5rem 60px 5rem;
    min-height: 400px;
  }

  .parentDivInvest2 {
    min-height: 400px;
    padding: 3rem 5rem;
  }

  .greenBackground {
    margin-bottom: 0;
    padding: 0;
  }

  .iHeaderDownload {
    font-size: 36px;
  }

  .parentDiv16 {
    padding: 0 5rem 10px 5rem;
    min-height: 400px;
  }

  .parentDiv3 {
    margin-top: 4rem;
    padding: 10px 5rem;
    min-height: 400px;
  }

  .parentDiv4 {
    padding: 10px 5rem;
    min-height: 350px;
  }

  .parentDiv5 {
    min-height: 100px;
  }

  .parentDiv6 {
    padding: 10px 5rem;
  }

  .parentDiv7 {
    padding: 10px 5rem;
  }

  .parentDiv8 {
    padding: 10px 5rem;
    min-height: 400px;
  }

  .parentDiv9,
  .parentDiv14,
  .parentDiv15 {
    padding: 10px 5rem;
  }

  .parentDivHyre {
    margin-top: 40px;
    padding: 10px 5rem 0 5rem;
  }

  .parentDiv11 {
    padding: 10px 5rem;
  }

  .parentDiv12 {
    align-items: center;
  }

  .parentPadding {
    padding: 10px 5rem;
  }

  .parentPadding1 {
    padding: 10px 5rem;
  }

  .parentPadding2 {
    padding: 10px 5rem 20px 5rem;
  }

  .ml1 {
    margin-left: 10rem;
  }

  .colouredDiv1 {
    width: 90vw;
    height: auto;
    margin-bottom: 2rem;
  }

  .iHeaderWidth {
    width: 440px;
  }

  .iHeaderWidthSave {
    width: 350px;
  }

  .iHeaderWidth2,
  .iHeaderDownload {
    width: 440px;
  }

  .savepwidth {
    width: 350px;
  }

  .colouredDiv1 {
    width: 400px;
  }

  .p2rem {
    padding: 1.2rem;
  }

  .securityWidth {
    width: 600px;
  }

  .commentDiv {
    width: 420px;
    height: 550px;
  }

  .started {
    width: 220px;
  }

  .widthR {
    width: 420px;
  }

  // .wordBreak {
  //     overflow-wrap: break-word;
  //     word-wrap: break-word;
  //     hyphens: auto;
  // }

  .m2 {
    margin: 4rem 0 0 0;
  }

  .mm2 {
    margin: -200px 0 0 0;
  }

  .mtb0lr1 {
    margin: 0;
  }

  .mthome {
    margin-top: -100px;
  }

  .boxButton {
    margin: -1rem 0 3rem 1rem;
  }

  .paragraph {
    font-size: 16px;
    line-height: 28px;
    color: #606060;
    // width: 85vw;
  }

  .p15,
  .p20,
  .p25 {
    padding: 0 5rem;
    white-space: normal;
  }

  //save page
  .saveBox1 {
    // width: 240px;
    height: 235px;
  }

  .saveBox2 {
    // width: 240px;
    height: 235px;
  }

  .saveBox3 {
    // width: 240px;
    height: 235px;
  }

  //invest page
  .investBox {
    // width: 220px;
    height: 370px;
  }

  //pay bills page
  .payBillsBox {
    width: 250px;
    height: 300px;
  }

  //about us
  .aboutDiv {
    width: 85vw;
  }

  //calculator
  .calculatorWidth {
    width: 300px;
  }

  .calculatorWidth2 {
    width: 400px;
  }

  .calculatorDiv1 {
    width: 400px;
    margin-right: 2rem;
  }

  .calculatorDiv2 {
    width: 400px;
  }

  .calculatorFormPadding {
    padding: 3.5rem 3rem;
  }

  .calculatorResutDiv {
    width: 200px;
    height: 120px;
    background: #47c20c;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1300px) {
  .stepsChildDiv {
    p {
      br {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1300px) {
  .p25 {
    padding: 0 15rem;
  }

  .textWidth2 {
    width: 480px;

    p {
      width: 80%;
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 899px) {
  .textWidth2 {
    width: 360px;

    h1 {
      font-size: 5rem;
      line-height: 6rem;
    }

    p {
      width: 80%;
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .paddingFaq {
    padding: 100px 0 50px 0;
  }
}

@media all and (device-width: 769px) and (device-height: 1024px) and (orientation: portrait) {
  .investBox {
    width: 80vw;
    height: auto;
  }
}

@media (max-width: 768px) {
  .investButton {
    margin-bottom: 1rem;
  }

  .pt2 {
    padding-top: 2rem;
    padding-left: 0;
  }

  .pInvest {
    padding: 4rem 0;
  }

  .mtMobile {
    margin-top: 1rem;
  }

  .mtMobile2 {
    margin-top: 7rem;
  }

  .mt100 {
    margin: 30px auto;
  }

  .mtb100 {
    margin: 50px 0 80px 0;
  }

  .mtbSave {
    margin: 40px 0 -10px 0;
  }

  .container {
    margin: 60px 0 0 0;
    min-height: 100vh;
    max-height: auto;
  }

  .greenBackground {
    margin: 0;
    padding: 0;
  }

  .parentDiv {
    display: block;
    padding: 10px 5vw;
    white-space: normal;
    text-align: center;
  }

  .parentDiv1 {
    display: block;
    padding: 10px 5vw;
  }

  .parentDiv2,
  .parentDiv12,
  .parentDivSave,
  .parentDivDownload,
  .parentDivCenter,
  .parentDivInvest,
  .parentDivInvest2 {
    display: block;
    padding: 10px 5vw !important;
  }

  .parentDivSave2 {
    display: block;
    margin: 30px 0 0 0;
    padding: 10px 5vw !important;
    min-height: 900px;
  }

  .parentDiv13 {
    display: flex;
    /* Restore flex for mobile reverse */
    flex-direction: column-reverse;
    padding: 10px 5vw !important;
  }

  .parentDivHome {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 5vw 10px 5vw !important;
  }

  .parentDivHome2 {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 5vw 10px 5vw !important;
  }

  .parentDiv16 {
    display: block;
    padding: 0 5vw 10px 5vw !important;
  }

  .parentDiv4 {
    display: block;
    padding: 10px 5vw 30px 5vw;
  }

  .parentDiv5 {
    display: block;
    padding-bottom: 20px;
  }

  .parentDiv6 {
    display: block;
    padding: 20px 5vw 0 5vw;
  }

  .parentDiv7 {
    display: block;
    padding: 1rem;
  }

  .parentDiv8 {
    display: block;
  }

  .parentDiv9,
  .parentDiv14,
  .parentDiv15 {
    display: block;
    padding: 10px 5vw 50px 5vw;
  }

  .parentDivHyre {
    display: block;
    padding: 10px 5vw 0 5vw;
  }

  .parentDiv11 {
    display: block;
    padding: 30px 5vw;
  }

  .parentPadding {
    padding: 10px 5vw;
  }

  .parentPadding1 {
    padding: 10px 5vw 40px 5vw;
  }

  .parentPadding2 {
    padding: 5px 0 30px 0;
    display: block;
  }

  .paddingFaq {
    padding: 50px 0;
  }

  .colouredDiv1 {
    width: 90vw;
    height: auto;
    margin-bottom: 2rem;
  }

  .colouredDiv2 {
    width: 90vw;
    height: auto;
  }

  .colouredDivHeader {
    font-size: 24px;
    line-height: 32px;
    margin-top: 2rem;
  }

  .p2 {
    padding: 0.5rem 1rem;
  }

  .p2rem {
    padding: 0;
  }

  .p5 {
    padding: 0;
  }

  .p15,
  .p20,
  .p25 {
    padding: 0;
  }

  .divDimension {
    padding: 10px 5vw;
  }

  .leftDiv,
  .leftDivInvest {
    width: 90vw;
    margin: 0;
    margin-bottom: 20px;
  }

  .rightDiv,
  .rightDivInvest {
    width: auto;
    margin: 16px auto;
  }

  .rightDiv1 {
    width: 85vw;
    margin: 16px 0 0 0;
  }

  .leftDiv2,
  .leftDivSave {
    width: 90vw;
    margin: 0 auto;
  }

  .leftDiv4,
  .leftDivSave {
    width: 90vw;
    margin: 0 auto;
  }

  .leftDiv5,
  .leftDiv8 {
    width: 90vw;
    margin: 0 auto;
  }

  .rightDiv5,
  .rightDiv8 {
    width: 90vw;
    margin: 0;
  }

  .rightDiv2 {
    width: 90vw;
    margin: 0 auto;
  }

  .rightDiv3 {
    width: 90vw;
    margin: 0 auto;
  }

  .rightDiv4 {
    width: 90vw;
    margin: 0 auto;
  }

  .rightDiv6,
  .rightDiv7 {
    display: block;
    width: 90vw;
    margin: 0 auto;
  }

  .rightDivSave {
    display: flex;
    justify-content: center;
    width: 90vw;
    margin: 0 auto;
    height: auto;
  }

  .flex3 {
    display: block;
  }

  .divflex {
    justify-content: center;
  }

  .divflex h2 {
    margin: 0;
    padding: 0;
    width: 90vw;
  }

  .imageDiv img {
    margin: 10px auto;
    display: flex;
    justify-content: center;
  }

  .imageDiv2 img {
    width: 100%;
    height: 100%;
    margin: 0 auto 30px auto;
    display: flex;
    justify-content: center;
  }

  .imageDivInvest img {
    width: 60%;
    height: 60%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    // width: 80vw;
  }

  .imageDiv3 img {
    margin: 30px auto 0 auto;
    display: flex;
    justify-content: center;
  }

  .imageDiv5 img {
    margin: 10px auto;
    display: flex;
    justify-content: center;
    // width: 100vw;
  }

  .imageDiv6 img {
    margin: 10px auto;
    display: flex;
    justify-content: center;
    height: 90%;
    width: 90%;
  }

  .image {
    width: 80vw;
    height: 300px;
    display: block;
    margin: 0 auto;
  }

  .img {
    width: 90%;
    display: block;
    margin: 1rem auto;
  }

  .img2 {
    width: 80%;
    display: block;
    margin: 1rem auto;
  }

  .securityWidth {
    width: 70vw;
  }

  .iHeaderWidth,
  .iHeaderSave {
    width: 100%;
    margin-bottom: 20px;
  }

  .iHeaderWidth2,
  .iHeaderDownload {
    width: 100%;
  }

  .headerWidth {
    width: 100%;
  }

  .iParagraphWidth {
    width: 90vw;
  }

  .started {
    width: 90vw;
  }

  .commentDiv {
    padding: 1rem;
    width: 80vw;
    height: auto;
  }

  .m1 {
    margin: 0;
  }

  .m2 {
    margin: 1rem 0;
  }

  .mtb1lr0,
  .mtb1lr4 {
    margin: 0;
  }

  .mtb0lr1 {
    margin: 0;
  }

  .mm2 {
    margin: 0;
  }

  .mthome {
    margin-top: -90px;
  }

  .ml1 {
    margin-left: 0;
  }

  .mtb0l3 {
    margin: 0;
  }

  .mtb0r3 {
    margin: 0;
  }

  .mt3 {
    margin-top: -10px;
  }

  .mt4 {
    margin-top: -10px;
  }

  .mt5 {
    margin: 0;
  }

  .mt7 {
    margin-top: -20px;
  }

  .p3 {
    padding: 2rem 1rem 0 1rem;
  }

  .widthR {
    width: 90vw;
  }

  .wordBreak {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .boxButton {
    margin: 0 20px 50px 20px;
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bullet {
    padding-left: 24px;
  }

  .marginList {
    margin: 1rem;
  }

  .buttonTextInvest {
    font-size: 20px;
  }

  //save page
  .mtsave {
    margin-top: 20px;
  }

  .savingGridWidth {
    width: 70vw;
  }

  .saveBox1 {
    width: auto;
  }

  .saveBox2 {
    width: auto;
    margin-top: 1rem;
  }

  .saveBox3 {
    width: auto;
    margin-top: 1rem;
  }

  .savepwidth {
    width: 90vw;
  }

  .saveImg {
    background-image: url("https://res.cloudinary.com/dztw4cjew/image/upload/v1676388868/Assets/save/save2_.webp");
    width: 100%;
    background-size: cover;
  }

  .flexContainer {
    flex-direction: column;
  }

  .childDiv,
  .stepsChildDiv {
    width: 90%;

    p {
      br {
        display: none;
      }
    }
  }

  .childDiv img {
    height: 300px;
  }

  .childDivOne,
  .childDivThree,
  .childDivFive {
    margin-bottom: 20px;
  }

  .textPadding h3,
  .textPadding2 h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .childDivThree h3 {
    font-size: 32px;
    line-height: 40px;
  }

  .textPadding p,
  .textPadding2 p {
    font-size: 1.6rem;
    margin: 0 20px;
    line-height: 28px;

    br {
      display: none;
    }
  }

  .childDivThree p {
    font-size: 1.6rem;
    margin: 0;
    line-height: 28px;

    br {
      display: none;
    }
  }
  //invest page

  .textWidth,
  .textWidth2 {
    width: 90vw;
  }

  .stepsHeader,
  .stepsHeaderFull {
    width: auto;
    font-size: 32px;
    line-height: 40px;
    margin-top: 30px;
  }

  .investBox {
    width: auto;
    height: auto;
    font-size: 16px;
  }

  // .investImg {
  //     background-image: url("https://res.cloudinary.com/dztw4cjew/image/upload/v1676368422/Assets/invest/invest1.png");
  //     width: 80%;
  //     background-size: cover;
  // }

  .flex4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex2 {
    display: block;
  }

  .steps {
    display: none;
  }

  .m2b {
    margin: 0 0 40px 0;
  }

  .flexColumn {
    margin-left: 0;
  }

  .centerMobile {
    text-align: center;
  }

  //privacy-policy
  .headerDiv {
    padding: 5px 30px;
    min-height: 80px;
    height: fit-content;
  }

  .subHeaderDiv {
    padding: 5px 30px;
    min-height: 60px;
    height: fit-content;
  }

  //pay bills
  .payBillsMargin {
    margin: 0.5rem 3rem;
  }

  .payBillsBox {
    width: 85vw;
    height: 350px;
  }

  //about us
  .aboutDiv {
    width: 85vw;
    padding: 1rem;
  }

  .mtLines {
    display: none;
  }

  .aboutDivWidth1 {
    width: auto;
    display: block;
  }

  .aboutDivWidth2 {
    width: auto;
    display: block;
  }

  //calculator
  .calculatorWidth {
    width: 85vw;
  }

  .calculatorWidth2 {
    width: 85vw;
  }

  .calculatorFlex {
    display: block;
  }

  .calculatorDiv1 {
    width: 85vw;
    margin-right: 0;
  }

  .calculatorDiv2 {
    width: 85vw;
    margin-top: 2rem;
  }

  .calculatorFormPadding {
    padding: 2rem;
  }

  .savingsHeader {
    display: none;
  }

  .savingsHeaderMobile {
    display: block;
  }

  .educationHeader {
    display: none;
  }

  .educationHeaderMobile {
    display: block;
  }

  .retirementHeader {
    display: none;
  }

  .retirementHeaderMobile {
    display: block;
  }

  .divflexColumnMobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  //not found
  .parentPadding3 {
    width: 70%;
    margin: 0 auto 60px auto;
  }

  .mdownload {
    margin: 90px 0 50px 0;
  }

  .feesBox,
  .hyreBox {
    .header {
      font-size: 24px;
    }
  }

  .feesBox {
    height: 350px;
    padding: 19px;
    gap: 50px;
    margin: 10px 0;
  }
}

@media (max-width: 400px) {
  .flex {
    display: block;
  }

  .flex2 {
    display: block;
  }

  .downloadButton {
    width: auto;
    margin: 0 auto 20px auto;
  }

  .downloadButton2 {
    width: auto;
    margin: 0 auto 20px auto;
  }

  .downloadButton3 {
    width: auto;
    margin: 0 auto 20px auto;
  }

  .headerDiv {
    font-size: 3rem;
    min-height: 80px;
    height: -moz-fit-content;
    height: fit-content;
    width: auto;
  }

  .subHeaderDiv {
    padding: 5px 15px;
  }

  .mb1 {
    margin-bottom: 2.5rem;
  }

  .m90 {
    margin: 40px 0;
  }

  .mdownload {
    margin: 0;
  }

  .mPrivacy {
    margin: 90px 0 40px 0;
  }

  .mthome {
    margin-top: -40px;
  }
}

@media (min-width: 2200px) {
  .mtb0l3 {
    margin: 0 0 0 1rem;
  }

  .ml1 {
    margin-left: 15rem;
  }

  .rightDiv6 {
    margin-left: 12rem;
  }

  .mthome {
    margin-top: -90px;
  }
}
